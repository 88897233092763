<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('board_decision')"
				        :isFilter="false"/>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('board_decision')"
				              :isFilter="false"/>
			</template>
			<div class="row mb-5 align-items-center">
				<div class="col-12">
					<ValidationObserver ref="storeForm">
						<div class="row">
							<div class="col-xl-3 col-md-4 col-sm-6 col-xs-12">
								<ValidationProvider name="faculty_code" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('faculty')">
										<faculty-selectbox
											v-model="form.faculty_code">
										</faculty-selectbox>
										<b-form-invalid-feedback v-if="errors[0]"
										                         v-html="errors[0]"></b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>

							<div class="col-xl-3 col-md-4 col-sm-6 col-xs-12">
								<ValidationProvider name="semester_id" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('semester')">
										<semesters-selectbox
											v-model="form.semester_id" :setActive="true">
										</semesters-selectbox>
										<b-form-invalid-feedback v-if="errors[0]"
										                         v-html="errors[0]"></b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>

							<div class="col-xl-3 col-md-4 col-sm-6 col-xs-12">
								<ValidationProvider name="date" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('date')">
                                        <select-date v-model="form.date" :validation-error="errors[0]"/>
									</b-form-group>
								</ValidationProvider>
							</div>

							<div class="col-xl-3 col-md-4 col-sm-6 col-xs-12">
								<ValidationProvider name="number" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('meeting_number')">
										<b-form-input
											:validate-error="errors[0]"
											v-model="form.number">
										</b-form-input>
										<b-form-invalid-feedback v-if="errors[0]"
										                         v-html="errors[0]"></b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>

							<div class="col-xl-3 col-md-4 col-sm-6 col-xs-12">
								<ValidationProvider name="decision_number" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('decision_number')">
										<b-form-input
											:validate-error="errors[0]"
											v-model="form.decision_number">
										</b-form-input>
										<b-form-invalid-feedback v-if="errors[0]"
										                         v-html="errors[0]"></b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>

							<div class="col-xl-3 col-md-4 col-sm-6 col-xs-12">
								<ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('subject')">
										<parameter-selectbox
											:validate-error="errors[0]"
											code="board_decision_types"
											v-model="form.type"
										></parameter-selectbox>
									</b-form-group>
								</ValidationProvider>
							</div>

							<div class="col-xl-3 col-md-4 col-sm-6 col-xs-12">
								<ValidationProvider name="meeting_place" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('meeting_place')">
										<b-form-input
											:validate-error="errors[0]"
											v-model="form.meeting_place">
										</b-form-input>
										<b-form-invalid-feedback v-if="errors[0]"
										                         v-html="errors[0]"></b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</div>

							<div class="col-12" v-if="memberOptions.length>0">
								<ValidationProvider name="attendees" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('attendees')">
										<b-form-checkbox-group
											:validate-error="errors[0]"
											:options="memberOptions"
											v-model="form.attendees">
										</b-form-checkbox-group>
									</b-form-group>
								</ValidationProvider>
							</div>

						</div>
					</ValidationObserver>
				</div>
				<div class="col-12 mt-3 d-flex">
					<b-button @click="store"
					          type="button"
					          variant="primary"
					          class="btn-lg mr-2" v-if="form.attendees && form.attendees.length>0">
						{{ $t('save').toUpper() }}
					</b-button>
					<b-button v-if="this.form.id!=null" @click="document()"
					          type="button"
					          variant="outline-primary"
					          class="btn-lg">
						{{ $t('fykk_template').toUpper() }}
					</b-button>

				</div>
			</div>
		</app-layout>
	</div>
</template>
<script>
	// Template
	import AppLayout from "@/layouts/AppLayout";
	import Header from "@/layouts/AppLayout/Header";
	import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

	// Component
	import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
	import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
	import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
	import BoardDecisionService from "@/services/BoardDecisionService";
	import BoardMemberService from "@/services/BoardMemberService";

	// Other
	import {ValidationObserver, ValidationProvider} from "vee-validate";
	import qs from "qs";
    import SelectDate from "@/components/interactive-fields/SelectDate";

	export default {
		components: {
            SelectDate,
			AppLayout,
			Header,
			HeaderMobile,
			FacultySelectbox,
			SemestersSelectbox,
			ParameterSelectbox,
			ValidationProvider,
			ValidationObserver
		},
		data() {
			return {
				id: this.$route.params.id,
				form: {},
				memberOptions: [],
			}
		},
		metaInfo() {
			return {
				title: this.$t("board_decision")
			}
		},
		created() {
			this.loadData();
		},
		methods: {
			loadData() {
				if (this.id > 0) {
					BoardDecisionService.get(this.id)
					                    .then(response => {
						                    this.form = response.data.data;
					                    }).catch(error => {
						if (error.data.message) {
							this.$toast.error(this.$t('api.' + error.data.message));
						}
					});
				}
			},
			async store() {
				const isValid = await this.$refs.storeForm.validate();
				if (isValid) {
					BoardDecisionService.store(this.form)
					                    .then(response => {
						                    this.$toast.success(this.$t('api.' + response.data.message));
						                    this.form = response.data.data;
					                    })
					                    .catch(e => {
						                    if (e.data.message) {
							                    this.$toast.error(this.$t('api.' + e.data.message));
						                    }
					                    });
				}
			},
			getMembers() {
				this.memberOptions = [];
				if (!this.form.faculty_code) {
					return;
				}
				const config = {
					params: {
						filter: {faculty_code: this.form.faculty_code},
						limit: 999,
						sort: 'id'
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false}),
				};
				BoardMemberService.getAll(config)
				                  .then(response => {
					                  response.data.data.map(item => {
						                  this.memberOptions.push({
							                  value: item.id,
							                  text: item.person.name + ' ' + item.person.surname
						                  });
					                  });
				                  })
				                  .catch(e => {
					                  if (e.status !== undefined && e.data.message) {
						                  this.$toast.error(this.$t('api.' + e.data.message));
					                  }
				                  });
			},
			document() {
				this.$router.push('/board/decisions/document/' + this.form.id)
			}
		},
		watch: {
			'form.faculty_code'() {
				this.getMembers();
			}
		}
	};
</script>
